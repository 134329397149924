import React, { useEffect } from "react";

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";

import AddressSearchInput from "components/common/AddressSearchInput";
import Image from 'components/common/Image';
import Faq from 'components/common/Faq';

import ProductsInfo from "components/cards/ProductsInfo";
import ProductHighlightNew from 'components/cards/ProductHighlightNew';
import RacInfo from "components/cards/RacInfo";
import CarbonOffsetInfo from "components/cards/CarbonOffsetInfo";
import FlybuysInfo from "components/cards/FlybuysInfo";
import AwardsInfo from 'components/cards/AwardsInfo';
import SignupNgInfoNew from "components/cards/SignupNgInfoNew";
import SignupNgInfoSummer from "components/cards/SignupNgInfoSummer";

import faqDataOffer from 'constants/components/accordion-list/natural-gas-faq';
import faqDataOfferSummer from 'constants/components/accordion-list/natural-gas-faq-summer';

import { isLpgDemergerOn } from 'constants/global';
import { useSummerCampaignActive } from 'constants/hooks/marketingEventToggles';


function NaturalGasProductPage() {
	const isSummerCampaignActive = useSummerCampaignActive();

	let heroBannerContent;

    if (isSummerCampaignActive === null) {
        heroBannerContent = <div></div>;
    } else if (isSummerCampaignActive) {
        heroBannerContent = (
            <SignupNgInfoSummer theme="mer-theme--cyan"
                background="mer-bg--primary-cyan"
                padding="mer-py-ju" />
        );
    } else {
        heroBannerContent = (
			<SignupNgInfoNew theme="mer-theme--cyan"
				background="mer-bg--primary-cyan"
				padding="mer-py-ju" />
        );
    }

	return (
		<>
            <SEO title="Natural Gas Supplier | WA's Local Provider - Kleenheat" 
				 titleBespoke="true"
            	 description="Join WA's local natural gas supplier. Enjoy award-winning local service and ongoing value with Kleenheat. Find a plan to suit your needs today."
        	/>
            <Header />
			
            <LayoutMain>		
				{heroBannerContent}

				<ProductHighlightNew panelBackground="mer-bg--ui-light"
						panelPadding="mer-pt-lg" />

				<div className={isSummerCampaignActive ? 'products-info' : ''} id="products-info">
					<ProductsInfo panelBackground="mer-bg--ui-light"
								panelPadding="mer-py-lg"
								id="pricing" />
				</div>
				
				<RacInfo  panelBackground="mer-bg--ui-light"
	            		  panelPadding="mer-py-de"
            			  ngAvailable={true} />

				<FlybuysInfo panelBackground="mer-bg--ui-light"
	            			 panelPadding="mer-py-de" />

				<CarbonOffsetInfo  panelBackground="mer-bg--ui-light"
	            		  		   panelPadding="mer-pt-de mer-pb-ju" />

				<AwardsInfo panelBackground="mer-bg--gs-grey-lighter" 
                    		panelPadding="mer-py-de" />

				<Faq panelBackground="mer-bg--ui-light"
		  			 panelPadding="mer-py-lg"
		  			 id="faq"
		  			 data={isSummerCampaignActive ? faqDataOfferSummer : faqDataOffer} />

			 	{/* address search */}
            	<LayoutPanel theme="mer-theme--light"
            				 padding="mer-py-lg"
            				 col="flex-sm-row-reverse"
                			 background="mer-bg--gs-grey-lighter"
		                	 id="service-availability" >
	                <div className="container">
	                    <div className="row mer-panel justify-content-center">

	                        <div className="col-10 col-sm-6 col-md-7 col-lg-5">
	                            <div className="content">
	                                <h2 className="mer-typography--headline3">
	                                	Is natural gas available in my area?
	                                </h2>
	                                <p>Kleenheat can supply natural gas to homes connected to WA’s distribution network across the Perth metropolitan area and spanning Geraldton to Busselton.</p>
	                            </div>
	                            
	                           	<form className="mt-3 mt-lg-5 mb-0 mer-text--size-lg">
	                                <fieldset>
	                                    <legend>Check availability at your address</legend>
	                                    <div className="row">
	                                        <div className="col-12">
	                                            <AddressSearchInput searchButtonText="Check address" isSsfRedirect={isLpgDemergerOn} />
	                                        </div>
	                                    </div>
	                                </fieldset>
	                            </form>
	                        </div>

	                        <div className="mer-panel__images col-8 col-sm-4 col-lg-3 offset-sm-1">

	                            <Image className="mer-panel__image"
	                                 src="/icons/flowmoji/flowmoji-search.svg"
	                                 alt="Flowmoji search"
	                          />
	                        </div>
	                    </div>
	                </div>
	            </LayoutPanel>

		    </LayoutMain>
			<Footer />
		</>
	)
}

export default NaturalGasProductPage;